.f12 {
  font-size: 12px;
}

.f13 {
  font-size: 13px;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.f16 {
  font-size: 16px;
}

.f18 {
  font-size: 18px;
}

.f20 {
  font-size: 20px;
}

.f24 {
  font-size: 24px;
}

.f30 {
  font-size: 30px;
}



.fb, .bold {
  font-weight: bold;
}

.fm {
  font-weight: 600;
}


.through {
  text-decoration: line-through;
}


.ac {
  text-align: center;
}

.al {
  text-align: left;
}

.ar {
  text-align: right;
}