.rel {
  position: relative;
}

.abs {
  position: absolute;
}

.fixed {
  position: fixed;
}