
.ml2 {
  margin-left: 2px;
}

.ml3 {
  margin-left: 3px;
}

.ml4 {
  margin-left: 4px;
}

.ml10 {
  margin-left: 10px;
}

.ml16 {
  margin-left: 16px;
}

.ml30 {
  margin-left: 30px;
}


.mt6 {
  margin-top: 6px;
}

.mt10 {
  margin-top: 10px;
}

.mt14 {
  margin-top: 14px;
}

.mt16 {
  margin-top: 16px;
}

.mt20 {
  margin-top: 20px;
}

.mt40 {
  margin-top: 40px;
}