.fff {
  color: #fff;
}

.c-gold {
  color: #fff2be;
}

.c-c7 {
  color: #c7c7c7;
}

.c0 {
  color: #000;
}

.c3 {
  color: #333
}
