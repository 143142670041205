.pl6 {
  padding-left: 6px;
}

.pl8 {
  padding-left: 8px;
}

.pt18 {
  padding-top: 18px;
}