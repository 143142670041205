.lb-painting-membership-features{
  align-items: stretch!important;
}

.lb-painting-chargeable-detail{
  width: 100%;
  padding: 0 24px;

  .lb-painting-chargeable-detail-item{
    width: 100%;
  }
}